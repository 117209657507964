import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import GridContent from '../components/UI/grid-content'

const AboutPage = ({ data, location }) => {
  const { about, partners, film } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  return (
    <Layout
      location={typeof window !== 'undefined' && location}
      crumbLabel="About Us"
    >
      <GatsbySeo
        title="About Us"
        description="Welcome to Irbisio, a fund rooted in respect for our planet’s biodiversity and urgent need for sustainable development."
      />

      <GridContent
        id="about"
        layout="--center-4"
        mode={about.mode}
        background={about.image}
      >
        {about.section && <h4>{about.section}</h4>}
        <h1>{about.title}</h1>
        <p className="markdown subtitle">{about.sub_title}</p>
        <div
          className="markdown"
          dangerouslySetInnerHTML={{ __html: about.body }}
        />
      </GridContent>
      <GridContent
        id="video"
        layout="--center-6"
        hide="true"
        invert={true}
        mode={film.mode}
        background={film.image}
      >
        {film.section && <h4>{film.section}</h4>}
        {film.introduction && (
          <h2 style={{ textAlign: 'center' }}>{film.introduction}</h2>
        )}
        <iframe
          width="100%"
          height="100%"
          src={film.embed}
          title="Irbisio Brand Film"
          frameBorder="0"
          style={{
            aspectRatio: '16 /9',
          }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </GridContent>

      {/* <GridContent
        id="partners"
        layout="--center-4"
        mode={partners.mode}
        background={partners.image}
      >
        <h4>{partners.section}</h4>
        {partners.details.map((partner, i) => {
          return (
            <div key={i}>
              <img
                src={partner.logo.publicURL}
                alt={partner.name}
                style={{ width: '100%' }}
              />
              <p className="markdown">{partner.body}</p>
              <a
                href={partner.button.link}
                style={{ display: 'block', textAlign: 'end' }}
              >
                {partner.button.label}
              </a>
            </div>
          )
        })}
      </GridContent> */}
    </Layout>
  )
}

export default AboutPage

export const query = graphql`
  query {
    allFile(filter: { name: { eq: "about" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              partners {
                details {
                  body
                  button {
                    label
                    link
                  }
                  logo {
                    publicURL
                  }
                }
                mode
                section
                image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              film {
                section
                mode
                introduction
                embed
                image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
              team {
                members {
                  body
                  name
                  photo {
                    childImageSharp {
                      gatsbyImageData(formats: PNG, placeholder: BLURRED)
                    }
                  }
                  title
                }
                image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                mode
                section
              }
              about {
                body
                mode
                section
                sub_title
                title
                image {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
